// Appearance Services

import { imgIconsDefault, imgWidgetIconsDefault } from "./Appearance.constant"

export const convertGiftIconToSave = (appearance, data, imgIcons) => {
  try {
    const iconUpload = imgIcons?.find((item) => item.id === data.gift_icon_src)
    const giftIconData = iconUpload ? (iconUpload?.id === "custom-icon" ? iconUpload.src : iconUpload.id) : imgIconsDefault[0].id
    const dataSave = {
      ...appearance,
      gift_icon: {
        gift_icon_path: giftIconData,
        product_page: {
          gift_icon: {
            status: data.show_gift_icon_product_page,
            size: data.show_gift_icon_product_page_size
          },
          gift_thumbnail: {
            status: data.show_gift_thumbnail_product_page,
            size: data.show_gift_thumbnail_product_page_size,
            title: data.show_gift_thumbnail_title_product_page
          }
        },
        collection_page: {
          status: data.show_gift_icon_collection_page,
          size: data.show_gift_icon_collection_page_size
        }
      }
    }

    return dataSave
  } catch (e) {
    console.log(e)
    return appearance
  }
}

export const convertTodayOfferToSave = (appearance, todayOffer, imgIcons) => {
  try {
    const iconUpload = imgIcons?.find((item) => item.id === todayOffer.widget_icon_src)
    const widgetIconData = iconUpload ? (iconUpload?.id === "custom-icon" ? iconUpload.src : iconUpload.id) : imgWidgetIconsDefault[0].id
    const dataSave = {
      ...appearance,
      today_offer: {
        widget_icon_path: widgetIconData,
        widget_info: {
          show_widget: todayOffer.show_widget,
          widget_position: todayOffer.widget_position,
          widget_title: todayOffer.widget_title,
          widget_subtitle: todayOffer.widget_subtitle,
          widget_icon_style: todayOffer.widget_icon_style ?? "icon",
          icon_widget_title: todayOffer.icon_widget_title ?? "Today offers"
        },
        widget_color: {
          header_background_color: todayOffer.header_background_color ?? "#FFFFFF",
          body_background_color: todayOffer.body_background_color ?? "#FFFFFF",
          widget_title_color: todayOffer.widget_title_color ?? "#5E5873",
          widget_subtitle_color: todayOffer.widget_subtitle_color ?? "#b9b9c3",
          offer_title_color: todayOffer.offer_title_color ?? "#82868b",
          offer_default_color: todayOffer.offer_default_color ?? "#ebe9f1",
          offer_success_color: todayOffer.offer_success_color ?? "#69CE82"
        }
      }
    }
    return dataSave
  } catch (e) {
    console.log(e)
    return appearance
  }
}

export const convertGiftSliderToSave = (appearance, data) => {
  try {
    const dataSave = {
      ...appearance,
      gift_slider: {
        general: {
          gift_popup_title: data.gift_popup_title,
          add_to_cart_btn_title: data.add_to_cart_btn_title,
          running_text: data.running_text,
          select_gift_btn: data.select_gift_btn,
          show_product_title: data.show_product_title,
          show_variant_title: data.show_variant_title,
          show_pagination: data.show_pagination,
          show_gift_for_customer: data.show_gift_for_customer,
          number_product_slider: data.number_product_slider
        },
        disable_slider_checkbox: {
          enable: data.show_disable_slider,
          time: data.number_minute_disable_slider,
          text: data.disable_slider_text
        },
        color: {
          gift_slider_title_color: data.gift_slider_title_color,
          product_title_color: data.product_title_color,
          original_price_color: data.original_price_color,
          discounted_price_color: data.discounted_price_color,
          add_to_cart_color: data.add_to_cart_color,
          add_to_cart_btn_color: data.add_to_cart_btn_color
        },
        others: {
          hidden_brand_mark: data?.hidden_brand_mark ?? false
        }
      }
    }

    return dataSave
  } catch (e) {
    console.log(e)
    return appearance
  }
}

export const convertPromotionalMsgToSave = (appearance, data) => {
  try {
    const dataSave = {
      ...appearance,
      promotion_message: {
        show_on_cart_page: data.show_on_cart_page ?? false,
        text_color: data.text_color,
        background_color: data.background_color
      }
    }

    return dataSave
  } catch (e) {
    console.log(e)
    return appearance
  }
}
