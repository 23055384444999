import mixpanel from "mixpanel-browser"
import { TRACKING_EVENTS, TRACK_QUERIES } from './TrackingEvents'

let _instance = null
const app_env = process.env.REACT_APP_ENV === 'production'
const debug = process.env.REACT_APP_ENV !== 'production'

class MixpanelTracking {
    constructor() {
        if (_instance) {
            throw new Error("MixpanelTracking is not allowed to be called. Please use MixpanelTracking.getInstance instead")
        }
        if (debug) {
            console.log("Mixpanel created")
        }
        if (app_env) {
            mixpanel.init(process.env.REACT_APP_MIXPANEL_ACCESS_TOKEN_KEY || "", {
                debug,
                ignore_dnt: true
            })
        }
    }

    getInstance() {
        if (!_instance) {
            _instance = new MixpanelTracking()
        }

        return _instance
    }

    track(name, data) {
        if (app_env) {
            const now = new Date()
            mixpanel.track(name, {
                ...data,
                $pathname: location.pathname,
                $local_date_time: now.toString(),
                $timestamp: now.getTime(),
                connection: window.navigator.connection?.effectiveType
            })
        }
    }

    identifyUserHasError(shop, error) {
        try {
            if (app_env && shop?.length > 0) {
                mixpanel.identify(shop)
                mixpanel.people.set({
                    $distinct_id: shop,
                    $name: shop,
                    $pathname: location.pathname
                })
                this.track(TRACKING_EVENTS.UNAUTHORIZED, error)
            }
        } catch (e) { }
    }

    identifyUser(data) {
        try {
            if (app_env) {
                const super_properties = {
                    $distinct_id: data.shop,
                    $name: data.shop,
                    $shop_id: data.shop_id,
                    $plan_id: data.plan_id,
                    $plan_name: data.plan_name,
                    $plan_display_name: data.plan_display_name,
                    $shop_owner: data.shop_owner,
                    $email: data.email,
                    $pathname: location.pathname,
                    $shopify_id: data.shopify_id
                }
                mixpanel.identify(data.shop)
                mixpanel.people.set(super_properties)
                mixpanel.register(super_properties)
            }
        } catch (e) { }
    }

    initTrackLinks() {
        try {
            if (app_env && Object.keys(TRACK_QUERIES ?? {}).length > 0) {
                for (const key in TRACK_QUERIES) {
                    console.log(key, TRACK_QUERIES[key])
                    const now = new Date()
                    mixpanel.track_links(
                        TRACK_QUERIES[key],
                        TRACKING_EVENTS[key],
                        {
                            pathname: location.pathname,
                            local_date_time: now.toString(),
                            timestamp: now.getTime(),
                            connection: window.navigator.connection?.effectiveType
                        })
                }
            }
        } catch (e) { }
    }

}

const mixpanelTracking = Object.freeze(new MixpanelTracking())

export default mixpanelTracking