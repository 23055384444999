export const TRACKING_EVENTS = {
    MENU: {
        Offers: "menu_offers_click",
        "Gift Slider": "menu_gift_slider_click",
        "Gift Icon": "menu_gift_icon_click",
        "Cart Message": "menu_cart_message_click",
        "Billing & Plans": "billing_plans_click",
        Settings: "menu_settings_click",
        Guideline: "guideline_click",
        Email: "contact_email_click",
        Livechat: "contact_livechat_click",
        "App status": "app_status_click"
    },
    MENU_CONFIRM_REDIRECT: {
        Offers: "menu_offers_confirm_click",
        "Gift Slider": "menu_gift_slider_confirm_click",
        "Gift Icon": "menu_gift_icon_confirm_click",
        "Cart Message": "menu_cart_message_confirm_click",
        "Billing & Plans": "billing_plans_confirm_click",
        Settings: "menu_settings_confirm_click",
        Guideline: "guideline_confirm_click",
        Email: "contact_email_confirm_click",
        Livechat: "contact_livechat_confirm_click",
        "App status": "app_status_confirm_click"
    },
    ALL_OFFERS: {
        "Apply change": "apply_change_click",
        Status: "offer_status_click",
        Edit: "edit_offer_click",
        Delete: "delete_offer_click",
        "Create offer": "create_offer_click",
        "Yes click": "offer_ques_yes_click",
        "No click": "offer_ques_no_chat",
        Duplicate: "offer_duplicate_click"
    },
    ALL_OFFERS_CONFIRM: {
        Delete: "delete_offer_confirm_click"
    },
    OFFER_DETAIL: {
        QUICK_TOUR: {
            "Start tour": "tour_start_click",
            skip: "tour_skip_click",
            back: "tour_back_click",
            next: "tour_next_click",
            finish: "tour_finish_click"
        },
        MAIN_CONDITION: {
            "Specific product condition": "specific_product_condition_click",
            "Cart quantity condition": "quantity_condition_click",
            "Cart value condition": "value_condition_click"
        },
        SUB_CONDITION: {
            "Specific link address": "specific_link_click",
            "Customers' Order History": "order_history_click",
            "Customer tags": "customer_tag_click"
        },
        "Select gifts": "select_gifts_click",
        Save: "save_offer_click",
        "Save and Apply": "save_apply_offer_click",
        Cancel: "cancel_offer_click",
        SEARCH_PRODUCT: "search_product"
    },
    CUSTOMIZE: {
        "Gift Icon": {
            "Save changes": "save_icon_click",
            Cancel: "cancel_icon_click"
        },
        "Gift Slider": {
            "Save changes": "save_slider_click",
            Cancel: "cancel_slider_click"
        },
        "Cart Message": {
            "Save changes": "save_message_click",
            Cancel: "cancel_message_click"
        },
        "Today Offer": {
            "Save changes": "save_today_offer_click",
            Cancel: "cancel_today_offer_click"
        }
    },
    SETTINGS: {
        Settings: {
            "Save changes": "save_settings_click",
            Cancel: "cancel_settings_click"
        },
        FRAUD_PROTECTION: {
            CANCEL_ORDER: "fraud_protection_cancel_order",
            CANCEL_ORDER_CONFIRM: "fraud_protection_cancel_order_confirm"
        }
    },
    APP_MANAGEMENT: {
        APP_STATUS: "app_status_click",
        APP_STATUS_CONFIRM: "app_status_confirm_click",
        APP_VERSION: "downgrade_click",
        APP_VERSION_CONFIRM: "downgrade_confirm_click"
    },
    ONBOARDING: {
        NEXT: "ob_start",
        DO_IT_LATER: "ob_skip",
        GO_TO_THEMES: "ob_to_theme",
        MARK_AS_DONE: "ob_done",
        EXPLORE_NOW: "ob_completed",
        CREATE_FIRST_OFFER: "ob_create_offer",
        BEFORE_CHARGE: "before_charge_click",
        BEFORE_CHARGE_VIEW: "before_charge_view",
        VIEW_ONLINE_STORE: "ob_view_store",
        BOOK_DEMO: "book_demo"
    },
    PARTNER: {
        TRY_IT_NOW: "recommend_partner_click",
        VIEW_MORE: "notification_partner_click",
        BANNER: "banner_partner_click"
    },
    UNAUTHORIZED: "unauthorized"
    // , CRISP_CHAT: "crisp_click"
}

export const TRACK_QUERIES = {
    // CRISP_CHAT: "#crisp-chatbox"
}