// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const api = `${process.env.REACT_APP_API_URL}/api`
const zendeskAPI = `${api}/email-contact-us`
const recommendedAppsAPI = `${api}/recommended-apps`
const bannersAPI = `${api}/banners`

export const zendeskMail = createAsyncThunk('others/zendesk', async (data) => {
    const response = await axios.post(`${zendeskAPI}`, data)
    return { data: response.data }
})

export const recommendedApps = createAsyncThunk('others/recommendedApps', async (params) => {
    const response = await axios.get(`${recommendedAppsAPI}`, { params })
    return { data: response.data }
})
export const getAllBanner = createAsyncThunk('others/getAllBanner', async (params) => {
    const response = await axios.get(`${bannersAPI}`, { params })
    return { data: response.data }
})

export const othersSlice = createSlice({
    name: 'others_shop',
    initialState: {
        params: {},
        success: false,
        message: '',
        shop: {},
        recommended_apps: [],
        effects_loading: {
            block_get_recommended_apps: true,
            block_get_banner: true
        },
        banners: [],
        openBanner: true
    },
    reducers: {
        changeEffects: (state, action) => {
            const { payload } = action
            for (const key in payload) {
                state.effects_loading[key] = payload[key]
            }
        },
        handleCloseBanner: (state, action) => {
            state.openBanner = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(recommendedApps.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.recommended_apps = action.payload.data.data?.sort((beforeVal, afterVal) => beforeVal.priority - afterVal.priority) ?? []
                }

                state.effects_loading.block_get_recommended_apps = false
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(recommendedApps.rejected, (state) => {
                state.effects_loading.block_get_recommended_apps = false
            })
            .addCase(zendeskMail.fulfilled, (state, action) => {
                state.shop = action.payload.data.shop
            })
            .addCase(getAllBanner.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.banners = action.payload.data.data?.sort((beforeVal, afterVal) => beforeVal.priority - afterVal.priority) ?? []
                }

                state.effects_loading.block_get_banner = false
            })
            .addCase(getAllBanner.rejected, (state) => {
                state.effects_loading.block_get_banner = false
            })
    }
})

export const { changeEffects, handleCloseBanner } = othersSlice.actions
export default othersSlice.reducer
