import { createSlice } from "@reduxjs/toolkit"
import { getAllNotification } from "./notification.thunk"

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: [],
    notifications_seen_id: [],
    loading: false
  },
  reducers: {
    setNotificationSeenId: (state, action) => {
      state.notifications_seen_id = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllNotification.fulfilled, (state, action) => {
      state.notifications = action.payload.data.data?.reverse()
      state.notifications_seen_id = action.payload.data.shop.notification_ids
      state.loading = false
    })
    .addCase(getAllNotification.pending, (state) => {
      state.loading = true
    })
  }
})

export default notificationSlice.reducer
export const { setNotificationSeenId } = notificationSlice.actions
