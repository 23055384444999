// ** Reducers Imports
import auth from './authentication'
import layout from './layout'
import navbar from './navbar'
// import todo from '@src/views/apps/todo/store'
// import chat from '@src/views/apps/chat/store'
// import users from '@src/views/apps/user/store'
// import email from '@src/views/apps/email/store'
// import invoice from '@src/views/apps/invoice/store'
// import calendar from '@src/views/apps/calendar/store'
// import ecommerce from '@src/views/apps/ecommerce/store'
// import dataTables from '@src/views/tables/data-tables/store'
// import permissions from '@src/views/apps/roles-permissions/store'
import handle_settings from '@offers/handle-settings/store'
import analytics from '@offers/store/analytic/Analytic'
import appearance from '@offers/store/appearance/Appearance'
import custom_config from '@offers/store/config/Config'
import notifications from '@offers/store/notification/notification.slice'
import offers from '@offers/store/offer/Offer'
import others from '@offers/store/others/Others'
import pricing from '@offers/store/pricing/Pricing'
import settings from '@offers/store/setting/Settings'
import products from '@offers/store/variant/Variants'
import fake from '@offers/zfake_coupon/store'

const rootReducer = {
  auth,
  // todo,
  // chat,
  // email,
  // users,
  navbar,
  layout,
  // invoice,
  // calendar,
  // ecommerce,
  // dataTables,
  // permissions,
  offers,
  products,
  settings,
  appearance,
  fake,
  handle_settings,
  analytics,
  custom_config,
  notifications,
  pricing,
  others
}

export default rootReducer
