import { Monitor, ShoppingBag, TrendingUp } from 'react-feather'

export const METRICS = {
    total_order: {
        title: "Orders",
        short: "Orders",
        icon: <ShoppingBag size={21} />,
        color: 'info',
        priority: 3,
        stats: "-",
        renderStats: (<h2 className={'fw-bold'}>-</h2>)
    },
    total_sales: {
        title: "Total sales",
        short: "Sales",
        icon: <Monitor size={21} />,
        color: 'warning',
        priority: 1,
        stats: "-",
        renderStats: (<h2 className={'fw-bold'}>-</h2>)
    },
    average_order_value: {
        title: "Average order value",
        short: "AOV",
        icon: <TrendingUp size={21} />,
        color: 'primary',
        priority: 2,
        stats: "-",
        renderStats: (<h2 className={'fw-bold'}>-</h2>)
    }
}