// ** Images
import fgIconBlue from '@src/assets/images/freegifts/fg-icons/fg-icon-blue.webp'
import fgIconGreen from '@src/assets/images/freegifts/fg-icons/fg-icon-green.webp'
import fgIconOrange from '@src/assets/images/freegifts/fg-icons/fg-icon-orange.webp'
import fgIconRed from '@src/assets/images/freegifts/fg-icons/fg-icon-red.webp'
import fgIconYellow from '@src/assets/images/freegifts/fg-icons/fg-icon-yellow.webp'

import widgetIcon1 from '@src/assets/images/freegifts/widget-icons/widget-icon-1.svg'
import widgetIcon2 from '@src/assets/images/freegifts/widget-icons/widget-icon-2.svg'
import widgetIcon3 from '@src/assets/images/freegifts/widget-icons/widget-icon-3.svg'
import widgetIcon4 from '@src/assets/images/freegifts/widget-icons/widget-icon-4.svg'
import widgetIcon5 from '@src/assets/images/freegifts/widget-icons/widget-icon-5.svg'
import widgetIcon6 from '@src/assets/images/freegifts/widget-icons/widget-icon-6.svg'


// ** Vars
const REACT_APP_API_URL = process.env.REACT_APP_API_URL

export const imgIconsDefault = [
    {
        id: 'images/fg-icons/fg-icon-red.png',
        src: fgIconRed,
        alt: 'fg-icon-red'
    },
    {
        id: 'images/fg-icons/fg-icon-orange.png',
        src: fgIconOrange,
        alt: 'fg-icon-orange'
    },
    {
        id: 'images/fg-icons/fg-icon-yellow.png',
        src: fgIconYellow,
        alt: 'fg-icon-yellow'
    },
    {
        id: 'images/fg-icons/fg-icon-blue.png',
        src: fgIconBlue,
        alt: 'fg-icon-blue'
    },
    {
        id: 'images/fg-icons/fg-icon-green.png',
        src: fgIconGreen,
        alt: 'fg-icon-green'
    }
]

export const imgWidgetIconsDefault = [
    {
        id: 'images/widget-icons/widget-icon-1.png',
        src: widgetIcon1,
        alt: 'widget-icon-1'
    },
    {
        id: 'images/widget-icons/widget-icon-2.png',
        src: widgetIcon2,
        alt: 'widget-icon-2'
    },
    {
        id: 'images/widget-icons/widget-icon-3.png',
        src: widgetIcon3,
        alt: 'widget-icon-3'
    },
    {
        id: 'images/widget-icons/widget-icon-4.png',
        src: widgetIcon4,
        alt: 'widget-icon-4'
    },
    {
        id: 'images/widget-icons/widget-icon-5.png',
        src: widgetIcon5,
        alt: 'widget-icon-5'
    },
    {
        id: 'images/widget-icons/widget-icon-6.png',
        src: widgetIcon6,
        alt: 'widget-icon-6'
    }
]

export const sizeOptions = ['32', '50', '100', '160', '240']

export const positionWidgetOptions = ['bottom_left', 'bottom_right']

export const styleWidgetOptions = ['icon', 'icon_and_title']
