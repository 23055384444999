// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Service Imports
import { imgIconsDefault, imgWidgetIconsDefault } from './Appearance.constant'

import { convertGiftIconToSave, convertGiftSliderToSave, convertPromotionalMsgToSave, convertTodayOfferToSave } from './Appearance.thunks'

const api = `${process.env.REACT_APP_API_URL}/api`
const appearanceAPI = `${api}/appearance`

export const getAppearance = createAsyncThunk('setting/getAppearance', async params => {
    const response = await axios.get(`${appearanceAPI}`, { params })
    return { params, data: response.data }
})

export const saveGiftIcon = createAsyncThunk('setting/saveGiftIcon', async ({ appearance, giftIcon, imgIcons }) => {
    const appearanceSave = convertGiftIconToSave(appearance, giftIcon, imgIcons)
    const response = await axios.post(`${appearanceAPI}`, appearanceSave)
    return { data: response.data }
})

export const saveTodayOffer = createAsyncThunk('setting/saveTodayOffer', async ({ appearance, todayOffer, widgetIcons }) => {
    const appearanceSave = convertTodayOfferToSave(appearance, todayOffer, widgetIcons)
    const response = await axios.post(`${appearanceAPI}`, appearanceSave)
    return { data: response.data }
})

export const saveGiftSlider = createAsyncThunk('setting/saveGIftSlider', async ({ appearance, giftSlider }) => {
    const appearanceSave = convertGiftSliderToSave(appearance, giftSlider)
    const response = await axios.post(`${appearanceAPI}`, appearanceSave)
    return { data: response.data }
})

export const savePromotionalMsg = createAsyncThunk('setting/savePromotionalMsg', async ({ appearance, promotionalMsg }) => {
    const appearanceSave = convertPromotionalMsgToSave(appearance, promotionalMsg)
    const response = await axios.post(`${appearanceAPI}`, appearanceSave)
    return { data: response.data }
})

export const appearanceSlice = createSlice({
    name: 'appearance_shop',
    initialState: {
        params: {},
        success: false,
        message: '',
        img_icons_arr: [...imgIconsDefault],
        img_widget_icons_arr: [...imgWidgetIconsDefault],
        func_trigger: null,
        appearance_format: {},
        appearance_change: {},
        appearance_has_change: false,
        status_save_appearance: false,
        appearance : {},
        shop: {},
        effects_loading: {
            block_get_appearance: true
        }
    },
    reducers: {
        changeEffects: (state, action) => {
            const { payload } = action
            for (const key in payload) {
                state.effects_loading[key] = payload[key]
            }
        },
        resetAppearance: (state) => {
            state.appearance = { ...state.appearance }
        },
        changeAppearance: (state, action) => {
            if (state.appearance_format && Object.entries(state.appearance_format).length > 0) {
                state.appearance_has_change = state.appearance_format !== action.payload
            } else {
                state.appearance_format = action.payload
            }
            state.appearance_change = JSON.parse(action.payload)
        },
        setInitChange: (state, action) => {
            state.appearance_format = action.payload
            state.appearance_change = JSON.parse(action.payload) 
            state.appearance_has_change = false
        },
        setSaveAppearance: (state, action) => {
            state.status_save_appearance = action.payload
        },
        setFuncTrigger: (state, action) => {
            state.func_trigger = action.payload
        },
        setImgIcons: (state, action) => {
            state.img_icons_arr = action.payload
        },
        setWidgetIcons: (state, action) => {
            state.img_widget_icons_arr = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAppearance.fulfilled, (state, action) => {
                state.params = action.payload.params
                if (action.payload.data.success) {
                    const appearance = action.payload.data.data ?? {}
                    const todayOfferDefault = {
                        widget_icon_path: "images/widget-icons/widget-icon-1.png",
                        shopify_widget_icon_path: "widget-icon-1.png",
                        widget_info: {
                          show_widget: false,
                          widget_position: "bottom_right",
                          widget_title: "TODAY OFFER",
                          widget_subtitle: "Claim these exclusive offers today"
                        }
                    }
                    state.appearance = appearance.today_offer ? appearance : { ...appearance, today_offer: todayOfferDefault} ?? {}
                    state.appearance_format = {}
                    state.appearance_has_change = false
                    state.status_save_appearance = false
                }

                state.effects_loading.block_get_appearance = false
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(getAppearance.rejected, (state) => {
                state.effects_loading.block_get_appearance = false
            })
            .addCase(saveGiftIcon.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.appearance = action.payload.data.data ?? {}
                    state.appearance_format = {}
                    state.appearance_has_change = false
                }

                state.status_save_appearance = false
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(saveGiftIcon.rejected, (state) => {
                state.status_save_appearance = false
            })
            .addCase(saveGiftSlider.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.appearance = action.payload.data.data ?? {}
                    state.appearance_format = {}
                    state.appearance_has_change = false
                }

                state.status_save_appearance = false
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(saveGiftSlider.rejected, (state) => {
                state.status_save_appearance = false
            })
            .addCase(savePromotionalMsg.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.appearance = action.payload.data.data ?? {}
                    state.appearance_format = {}
                    state.appearance_has_change = false
                }

                state.status_save_appearance = false
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(savePromotionalMsg.rejected, (state) => {
                state.status_save_appearance = false
            })
            .addCase(saveTodayOffer.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.appearance = action.payload.data.data ?? {}
                    state.appearance_format = {}
                    state.appearance_has_change = false
                }

                state.status_save_appearance = false
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(saveTodayOffer.rejected, (state) => {
                state.status_save_appearance = false
            })
    }
})

export const { changeEffects, resetAppearance, changeAppearance, setSaveAppearance, setFuncTrigger, setImgIcons, setInitChange, setWidgetIcons } = appearanceSlice.actions
export default appearanceSlice.reducer
