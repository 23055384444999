// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { empty } from '@utils'
import moment from 'moment'

// ** Axios Imports
import axios from 'axios'
import { convertDataShop } from './Config.thunk'

const api = `${process.env.REACT_APP_API_URL}/api`
const apiShop = `${api}/user`
const apiAppStatus = `${api}/app-status`
const apiStartGuide = `${api}/start-guide`
const aiCustomerIO = `${api}/update-customer-io`

export const getShop = createAsyncThunk('config/getShop', async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${apiShop}`, { params })
        if (response.status === 200) {
            return { params, data: response.data }
        } else {
            return rejectWithValue(response)
        }
    } catch (err) {
        if (!err.response) {
            throw err
        }

        return rejectWithValue(err.response)
    }

})

export const getAppStatus = createAsyncThunk('config/getAppStatus', async () => {
    const response = await axios.get(`${apiAppStatus}`)
    return { data: response.data }
})

export const updateStartGuide = createAsyncThunk('config/updateStartGuide', async data => {
    const response = await axios.put(`${apiStartGuide}`, data)
    return { data: response.data }
})

export const updateCustomerIO = createAsyncThunk('config/updateCustomerIO', async data => {
    const response = await axios.put(`${aiCustomerIO}`, data)
    return { data: response.data }
})

export const configSlice = createSlice({
    name: 'config_theme',
    initialState: {
        array_media: [],
        interval: -1,
        unauthorized: false,
        shop: {},
        before_charge: undefined,
        info_charge: {
            price: "0.00",
            redirectUri: '',
            trial_days: 7,
            charge_date: moment().add(7, 'days').format('MMMM D')
        },
        app_status: null,
        onboarding_process: 0,
        open_step_priority: null,
        fg_new_create_click: false,
        effects_loading: {
            block_get_shop: true,
            block_update_guide: false
        },
        open_start_guide: {
            dashboard: true,
            tab: false
        },
        start_guide_process: {
            text: '_/4',
            percentage: 0
        },
        check_popup_first_offer: false
    },
    reducers: {
        changeEffects: (state, action) => {
            const { payload } = action
            for (const key in payload) {
                state.effects_loading[key] = payload[key]
            }
        },
        setInterval: (state, action) => {
            state.interval = action.payload
        },
        setArrayMedias: (state, action) => {
            state.array_media = [...action.payload]
        },
        setAuthorization: (state, action) => {
            state.unauthorized = action.payload
        },
        setOnboardingProcess: (state, action) => {
            state.onboarding_process = action.payload
        },
        setFinishOnboarding: (state, action) => {
            state.shop.start_guide = {
                ...state.shop.start_guide,
                ...action.payload
            }
            state.onboarding_process = 0
        },
        setOpenStartGuide: (state, action) => {
            const { payload } = action
            for (const key in payload) {
                state.open_start_guide[key] = payload[key]
            }
        },
        setDoneProcessStartGuide: (state, action) => {
            state.shop.start_guide.process = {
                ...state.shop.start_guide.process,
                ...action.payload
            }

            const { shop, start_guide_process } = convertDataShop({
                ...state.shop
            })

            state.shop = { ...shop }
            state.start_guide_process = { ...start_guide_process }
        },
        setStepOpenPriority: (state, action) => {
            state.open_step_priority = action.payload
            state.open_start_guide.tab = true
        }
    },
    extraReducers: {
        [getShop.fulfilled]: (state, action) => {
            if (action.payload.data.success) {
                const { shop, start_guide_process } = convertDataShop({
                    ...state.shop,
                    ...action.payload.data.shop,
                    ...action.payload.data.data
                }, false)

                state.shop = { ...shop }
                window.FG_SHOPIFY_DOMAIN = shop.shop
                state.start_guide_process = { ...start_guide_process }
                // state.app_status = app_status ?? false
                if (!action.payload?.params?.not_check_onboarding) {
                    state.onboarding_process = state.shop?.start_guide?.onboarding ? 0 : 1
                }
                // state.check_popup_first_offer = 
            }
            state.before_charge = false
            state.effects_loading.block_get_shop = false
            state.effects_loading.block_update_guide = false
        },
        [getShop.rejected]: (state, action) => {
            const error = action.payload?.data?.errors?.data ?? {}
            if (!empty(error?.price) && error?.redirectUri?.includes('/charges/')) {
                state.before_charge = true
                state.info_charge = {
                    price: error.price ?? "0.00",
                    redirectUri: error.redirectUri,
                    trial_days: error?.trial_days ?? 7,
                    charge_date: moment().add(error?.trial_days ?? 7, 'days').format('MMMM D'),
                    shop: error?.shop_info
                }
            }

            state.effects_loading.block_get_shop = action.payload?.status === 401
            state.effects_loading.block_update_guide = false
        },
        [updateStartGuide.fulfilled]: (state, action) => {
            if (action.payload.data.success) {
                const { shop, start_guide_process, app_status } = convertDataShop({
                    ...state.shop,
                    ...action.payload.data.shop,
                    ...action.payload.data.data
                })

                state.shop = { ...shop }
                state.start_guide_process = { ...start_guide_process }
                state.app_status = app_status ?? state.app_status
                window.FG_STATUS_APP = state.app_status ? "on" : "off"
                state.effects_loading.block_update_guide = false
            }
        },
        [updateStartGuide.rejected]: (state) => {
            state.effects_loading.block_update_guide = false
        },
        [updateCustomerIO.fulfilled]: (state, action) => {
            if (action.payload.data.success) {
                state.fg_new_create_click = true
            }
        },
        [getAppStatus.fulfilled]: (state, action) => {
            if (action.payload.data.success) {
                const { app_status } = action.payload.data.data
                state.app_status = app_status ?? false
                window.FG_STATUS_APP = state.app_status ? "on" : "off"
                const { shop, start_guide_process } = convertDataShop({
                    ...state.shop,
                    ...action.payload.data.shop,
                    ...action.payload.data.data
                }, true)

                state.shop = { ...shop }
                state.start_guide_process = { ...start_guide_process }
            }
            state.effects_loading.block_update_guide = false
        },
        [getAppStatus.rejected]: (state) => {
            state.effects_loading.block_update_guide = false
        }
    }
})

export const {
    setInterval, setArrayMedias, setAuthorization, setOnboardingProcess, setFinishOnboarding, setOpenStartGuide,
    setDoneProcessStartGuide, setStepOpenPriority, changeEffects
} = configSlice.actions
export default configSlice.reducer
