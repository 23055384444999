import axios from 'axios'
import { getSessionToken, isShopifyEmbedded } from "@shopify/app-bridge-utils"
import { Redirect } from "@shopify/app-bridge/actions"
import { getShopifyDomain } from "../utils"
import { empty } from '@utils'
import jwtDefaultConfig from './jwtDefaultConfig'
import moment from 'moment'
import MIXPANEL_TRACKING from '@src/configs/mixpanel/mixpanelConfig'

export default class JwtServiceOverride {

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...jwtDefaultConfig, ...jwtOverrideConfig }
    // ** Request Interceptor
    axios.interceptors.request.use(
      async config => {
        const { app } = window
        const token = await getSessionToken(app)
        const host = window.__SHOPIFY_DEV_HOST
        const shop = getShopifyDomain()

        config.headers.Authorization = `${this.jwtConfig.tokenType} ${token}`
        config.headers['Access-Control-Allow-Origin'] = "*"
        config.params = {
          ...config.params,
          embedded: isShopifyEmbedded() ? 1 : 0,
          shop,
          host
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { response } = error
        // const originalRequest = config
        // ** if (status === 401) {
        if (response && response.status === 401) {
          const { app } = window
          const debug = process.env.REACT_APP_ENV !== 'production'
          const urlParams = new URLSearchParams(location.search)
          urlParams.delete("embedded")
          urlParams.set("host", window.__SHOPIFY_DEV_HOST)
          const shop = getShopifyDomain()
          if (!urlParams.has('shop')) {
            urlParams.set('shop', shop)
          }

          const redirect = Redirect.create(app)
          const urlAuth = `${process.env.REACT_APP_SHOPIFY_AUTH_URL}?${urlParams.toString()}`
          if (debug) {
            console.log("auth", urlAuth)
          }

          const checkFirstErrorIsCharge = window.BOGOS_BEFORE_CHARGE || location.pathname.includes('/before-charge')
          const error = response?.data?.errors?.data ?? {}

          MIXPANEL_TRACKING.getInstance()?.identifyUserHasError(error?.shop_info?.shop_name ?? shop, error)

          if (!empty(error?.price) && error?.redirectUri?.includes('/charges/')) {
            window.BOGOS_BEFORE_CHARGE = true
            window.BOGOS_CHARGE = {
              price: error.price ?? "0.00",
              redirectUri: error.redirectUri,
              trial_days: error?.trial_days ?? 7,
              charge_date: moment().add(error?.trial_days ?? 7, 'days').format('MMMM D'),
              shop: error?.shop_info
            }
            redirect.dispatch(Redirect.Action.APP, '/before-charge')
          } else if (!checkFirstErrorIsCharge) {
            window.BOGOS_BEFORE_CHARGE = false
            window.FG_AUTH_URL = urlAuth
            redirect.dispatch(Redirect.Action.APP, '/authentication')
            redirect.dispatch(
              Redirect.Action.REMOTE,
              urlAuth
            )
          }
        }
        return Promise.reject(error)
      }
    )
  }
}
