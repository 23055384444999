// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const api = `${process.env.REACT_APP_API_URL}/api`
const handleSettingsAPI = `${api}/handle-config-setting`

export const getHandleSettings = createAsyncThunk('handle/getConfig', async params => {
    const response = await axios.get(`${handleSettingsAPI}`, { params })
    return { data: response.data }
})

export const saveHandleSettings = createAsyncThunk('handle/saveConfig', async (handle_settings) => {
    const response = await axios.post(`${handleSettingsAPI}`, handle_settings)
    return { data: response.data }
})

export const HandleSettingsSlice = createSlice({
    name: 'handle_settings',
    initialState: {
        success: false,
        message: '',
        config: [],
        password: ''
    },
    reducers: {
        setPassword: (state, action) => {
            state.password = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getHandleSettings.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.config = action.payload.data.data ?? []
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
            })
            .addCase(saveHandleSettings.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.config = action.payload.data.data ?? []
                    for (const key in state.config) { 
                        state.config[key] = state.config[key] ?? ''
                    }
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
            })
    }
})

export const { setPassword } = HandleSettingsSlice.actions
export default HandleSettingsSlice.reducer
