import { mainOptionsKey, OFFER_STATE } from './Offer.constant'

export const convertData = (data) => {
    if (data && Object.entries(data).length > 0) {
        return {
            info: {
                id: data.id,
                offer_name: data.offer_name,
                offer_description: data.offer_description,
                start_time: Array.isArray(data.start_time) ? data.start_time[0] : data.start_time,
                end_time: Array.isArray(data.end_time) ? data.end_time[0] : data.end_time,
                priority: data.priority,
                status: data.status,
                stop_lower_priority: data.stop_lower_priority,
                promotional_message: data.promotional_message
            },
            condition: {
                offerCondition: data.offerCondition,
                condition_relationship: data.condition_relationship,
                delete_ids: data.delete_ids,
                gift_should_the_same_status: data.gift_should_the_same_status,
                gift_should_the_same_type: data.gift_should_the_same_type,
                condition_1: {
                    condition_id: data.condition_id,
                    min_amount: data.min_amount,
                    is_multiply: data.is_multiply,
                    max_amount: data.max_amount,
                    unit: data.unit
                },
                condition_2: {
                    condition_id_2: data.condition_id_2,
                    min_amount_2: data.min_amount_2,
                    is_multiply_2: data.is_multiply_2,
                    max_amount_2: data.max_amount_2,
                    unit_2: data.unit_2
                },
                product_condition: {
                    product_narrow_type: data.product_narrow_type,
                    product_narrow_value: data.product_narrow_value
                },
                sub_condition: {
                    subCondition: data.subCondition,
                    specific_link_address: data.specific_link_address,
                    customer_tags_type: data.customer_tags_type,
                    customer_tags_value: data.customer_tags_value,
                    customer_locations_type: data.customer_locations_type,
                    customer_locations_value: data.customer_locations_value,
                    history_order_created_from: data.history_order_created_from,
                    history_total_spend_on_order: data.history_total_spend_on_order,
                    history_total_spend_on_order_min: data.history_total_spend_on_order_min,
                    history_total_spend_on_order_max: data.history_total_spend_on_order_max,
                    history_total_spent_on_last_order: data.history_total_spent_on_last_order,
                    history_total_spent_on_last_order_min: data.history_total_spent_on_last_order_min,
                    history_total_spent_on_last_order_max: data.history_total_spent_on_last_order_max,
                    history_number_of_order: data.history_number_of_order,
                    history_number_of_order_min: data.history_number_of_order_min,
                    history_number_of_order_max: data.history_number_of_order_max,
                    history_have_not_got_gift_from_this_offer: data.history_have_not_got_gift_from_this_offer,
                    only_subscription_products: data.only_subscription_products
                }
            },
            gift: {
                items: data.items,
                settings: {
                    number_of_gift_can_receive: data.number_of_gift_can_receive,
                    // discount_percentage: data.discount_percentage,
                    discount: { ...data.discount },
                    cal_gift_for_other_rules: data.cal_gift_for_other_rules,
                    auto_add_all_gifts: data.auto_add_all_gifts,
                    customer_will_receive: data.customer_will_receive
                }
            }
        }
    } else {
        return {
            info: {},
            condition: {},
            gift: {}
        }
    }
}

export const convertNarrowProductToShow = (productOptions, narrow) => {
    narrow = narrow ?? []
    let typeSelect = productOptions.product_any
    let valueSelect = null
    const typeVendorCollection = ["product_types", "product_vendors", "product_collections"]
    const first = narrow[0] ?? {}

    if (typeVendorCollection.includes(first.type)) {
        typeSelect = first?.filter_option === "include" ? productOptions.type_vendor_collection :  productOptions.exclude_type_vendor_collection ?? productOptions.type_vendor_collection 
        valueSelect = {}

        narrow.forEach(item => {
            const type = item.type
            const valueType = item.value

            valueSelect[type.split("_")[1]] = []
            for (const [key, value] of Object.entries(valueType)) {
                valueSelect[type.split("_")[1]].push({ value: key, label: value })
            }
        })
    } else if (first.type === productOptions.variants_ids.value) {
        typeSelect = first?.filter_option === "include" ? productOptions.variants_ids : productOptions.exclude_variants_ids ?? productOptions.variants_ids
        valueSelect = {
            products: Object.values(first.value ?? {})
        }
    }

    return {
        type: typeSelect,
        value: valueSelect
    }
}

const convertNarrowProductToSave = (productOptions, type, value) => {
    const narrow = []
    const typeVendorCollection = {
        types: "product_types",
        vendors: "product_vendors",
        collections: "product_collections"
    }

    if (type.value.includes("variants_ids")) {
        const filterOption = type.value === productOptions.variants_ids.value ? "include" : "exclude"
        const typeSelect = "variants_ids"
        let valueSelect = {}
        value = value.products ?? []

        if (Array.isArray(value)) {
            value.forEach(item => {
                valueSelect[item.id] = item
            })
        } else {
            valueSelect = value
        }

        narrow.push({
            filter_option: filterOption,
            type: typeSelect,
            value: valueSelect
        })

    } else if (type.value.includes("type-vendor-collection")) {
        const filterOption = type.value === productOptions.type_vendor_collection.value ? "include" : "exclude"
        if (value.types && value.types.length) {
            const valTmp = {}
            value.types.forEach(type => {
                valTmp[type.value] = type.label
            })
            narrow.push({
                filter_option: filterOption,
                type: typeVendorCollection.types,
                value: valTmp
            })
        }
        if (value.vendors && value.vendors.length) {
            const valTmp = {}
            value.vendors.forEach(vendor => {
                valTmp[vendor.value] = vendor.label
            })

            narrow.push({
                filter_option: filterOption,
                type: typeVendorCollection.vendors,
                value: valTmp
            })
        }
        if (value.collections && value.collections.length) {
            const valTmp = {}
            value.collections.forEach(collection => {
                valTmp[collection.value] = collection.label
            })

            narrow.push({
                filter_option: filterOption,
                type: typeVendorCollection.collections,
                value: valTmp
            })
        }
    }

    return narrow
}

export const convertGiftItems = (data) => {
    const gifts = data ?? {}
    const giftItems = {}

    gifts.forEach(item => {
        const product = item.gift_product
        const id = product.original_product_shopify_id
        if (giftItems[id]) {
            giftItems[id].variant.push({
                id: item.original_variant_shopify_id,
                title: item.variant_title,
                price: item.original_price ?? 0,
                image: item.thumbnail
            })
        } else {
            giftItems[id] = {
                id,
                title: product.title.replace("» ", "")
                    .replace("🎁 ", "")
                    .replace(`(${item.discount_percentage ?? item.discount_value}% off)`, '')
                    .replace("(Discount)", ""),
                handle: item?.gift_product?.original_product_handle,
                price: product.price ?? 0,
                variant: [
                    {
                        id: item.original_variant_shopify_id,
                        title: item.variant_title,
                        price: item.original_price ?? 0,
                        image: item.thumbnail
                    }
                ],
                image: product.thumbnail
            }
        }
    })

    return Object.values(giftItems)
}

export const convertDataToSave = (subOptionsKey, productOptions, offerData, giftSchema) => {
    const tmp = {
        ...offerData.info,
        condition_relationship: offerData.condition.condition_relationship,
        delete_ids: offerData.condition.delete_ids,
        promotion_message: offerData.info.promotional_message && offerData.info.promotional_message.status ? offerData.info.promotional_message.value : null,
        conditions: [
            {
                ...offerData.condition.condition_1,
                min_amount: offerData.condition.condition_1.min_amount ? offerData.condition.condition_1.min_amount : 0,
                max_amount: offerData.condition.condition_1.max_amount ? offerData.condition.condition_1.max_amount : null,
                ...offerData.condition.condition_1.product_condition
            }
        ],
        gift_settings: {
            ...offerData.gift.settings,
            discount: {
                ...offerData.gift.settings?.discount,
                type: offerData.gift.settings?.discount?.type?.value ?? "percentage"
            },
            cal_gift_for_other_rules: offerData.gift.settings?.cal_gift_for_other_rules ?? false
        }
    }

    tmp.start_time = tmp.start_time ? new Date(tmp.start_time) : null
    tmp.end_time = tmp.end_time ? new Date(tmp.end_time) : null

    delete tmp.promotional_message

    // conditions
    const offerConditionTypes = offerData.condition.offerCondition
    const product_condition = offerData.condition.product_condition

    if (offerConditionTypes && offerConditionTypes.includes(mainOptionsKey.purchase_product_x.key)
        && product_condition.product_narrow_type?.value === 'variants_ids') {
        tmp.gift_should_the_same = {
            type: offerData.condition.gift_should_the_same_type,
            status: offerData.condition.gift_should_the_same_status
        }
    } else {
        tmp.gift_should_the_same = null
    }


    const narrow1 = convertNarrowProductToSave(productOptions, product_condition.product_narrow_type, product_condition.product_narrow_value)
    tmp.conditions[0].product_narrow = narrow1

    delete tmp.conditions[0].product_condition

    if (tmp.condition_relationship && offerData.condition.condition_2.unit_2) {
        const condition_2 = offerData.condition.condition_2

        // const narrow2 = convertNarrowProductToSave(productOptions, condition_2.product_condition.product_narrow_type_2, condition_2.product_condition.product_narrow_value_2)

        tmp.conditions.push({
            condition_id: condition_2.condition_id_2,
            min_amount: condition_2.min_amount_2 ? condition_2.min_amount_2 : 0,
            is_multiply: condition_2.is_multiply_2,
            max_amount: condition_2.max_amount_2 ? condition_2.max_amount_2 : null,
            unit: condition_2.unit_2,
            product_narrow: narrow1
        })
    }

    // sub conditions
    tmp.sub_conditions = {}
    const subCondition = offerData.condition.sub_condition ?? {}
    const subOptionsSelect = subCondition.subCondition ?? []
    if (subOptionsSelect.includes(subOptionsKey.specific_link_address)) {
        tmp.sub_conditions.specific_link_address = subCondition.specific_link_address
    }
    if (subOptionsSelect.includes(subOptionsKey.customer_tags)) {
        tmp.sub_conditions.customer_tags = {
            type_customer_tags: subCondition.customer_tags_type,
            value_customer_tags: subCondition.customer_tags_value?.filter(item => item.value)?.map(item => item.value) ?? []
        }
    }
    if (subOptionsSelect.includes(subOptionsKey.customer_locations)) {
        tmp.sub_conditions.customer_locations = {
            type_customer_locations: subCondition.customer_locations_type,
            value_customer_locations: subCondition.customer_locations_value?.filter(item => item.value)?.map(item => item.value) ?? []
        }
    }
    if (subOptionsSelect.includes(subOptionsKey.customer_order_history)) {
        const customer_order_history = {
            order_created_from: subCondition.history_order_created_from,
            total_spend_on_order_history: subCondition.history_total_spend_on_order ? {
                min_amount: subCondition.history_total_spend_on_order_min,
                max_amount: subCondition.history_total_spend_on_order_max
            } : null,
            total_spent_on_last_order: subCondition.history_total_spent_on_last_order ? {
                min_amount: subCondition.history_total_spent_on_last_order_min,
                max_amount: subCondition.history_total_spent_on_last_order_max
            } : null,
            number_of_order: subCondition.history_number_of_order ? {
                min_amount: subCondition.history_number_of_order_min,
                max_amount: subCondition.history_number_of_order_max
            } : null,
            have_not_got_gift_from_this_offer: subCondition.history_have_not_got_gift_from_this_offer
        }

        let checkExist = false
        for (const key in customer_order_history) {
            if (customer_order_history[key]) {
                checkExist = true
                break
            }
        }
        tmp.sub_conditions.customer_order_history = checkExist ? customer_order_history : null
    }
    if (subOptionsSelect.includes(subOptionsKey.subscription_products)) {
        tmp.sub_conditions.subscription_products = {
            is_only: subCondition.only_subscription_products
        }
    }

    // gift items
    const gifts = offerData.gift.items.map(item => {
        let variants_ids = []
        if (item.variant) {
            variants_ids = item.variant.map(variant => variant.id)
        }

        return {
            product_shopify_id: item.id,
            variants_ids
        }
    })

    tmp.gift_items = gifts

    // gift settings
    if (tmp.gift_settings.customer_will_receive === giftSchema.auto_add_all_gifts
        && tmp.gift_settings.discount?.type === "percentage" && tmp.gift_settings.discount?.value - 0 === 100) {
        tmp.gift_settings.auto_add_all_gifts = true
        tmp.gift_settings.number_of_gift_can_receive = tmp.gift_items?.reduce((total, item) => total + item.variants_ids?.length ?? 0, 0)
    } else if (tmp.gift_settings.customer_will_receive === giftSchema.numberGift) {
        tmp.gift_settings.auto_add_all_gifts = false
    } else {
        tmp.gift_settings.auto_add_all_gifts = false
    }

    return tmp
}

export const lastChangeStatus = (after, before) => {
    const afterOffers = {}
    after?.forEach(offer => {
        afterOffers[`offer_${offer.id}`] = { ...offer }
    })

    const diffOffersAfter = before?.filter(offer => afterOffers[`offer_${offer.id}`]?.loading === OFFER_STATE.JUST_UPDATED && offer.loading !== OFFER_STATE.NOT_RUNNING)

    return diffOffersAfter?.length ?? 0
}