import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const api = `${process.env.REACT_APP_API_URL}/api`
const notificationApi = `${api}/notifications`

export const getAllNotification = createAsyncThunk("notifications/getAllNotification", async () => {
  const response = await axios.get(`${notificationApi}`)
  return response
})

export const handleSeenNotifications = createAsyncThunk("notifications/handleSeenNotifications", async (data) => {
  const response = await axios.put(`${notificationApi}`, data)
  return response
})
